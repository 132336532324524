<template>
  <div class="video-show-index">
    <el-row :gutter="0">
      <el-col :span="3" class="left">
        <el-menu
            class="el-menu-vertical-demo"
            router>
          <el-menu-item :index="`/video/show/${$route.params.id}`">
            <i class="fa fa-video-camera"></i>
            <span slot="title">视频</span>
          </el-menu-item>
          <el-menu-item :index="`/video/show/${$route.params.id}/courseware`">
            <i class="fa fa-file-word-o"></i>
            <span slot="title">课件</span>
          </el-menu-item>
          <el-menu-item :index="`/video/show/${$route.params.id}/exam`">
            <i class="fa fa-expand"></i>
            <span slot="title">试卷</span>
          </el-menu-item>
          <!--      <el-menu-item index="2">-->
          <!--        <i class="el-icon-menu"></i>-->
          <!--        <span slot="title">导航二</span>-->
          <!--      </el-menu-item>-->
        </el-menu>
      </el-col>
      <el-col :span="21">
        <router-view></router-view>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "index"
}
</script>

<style scoped>
.video-show-index {
  /*display: flex;*/
  width: 100%;
  padding: 0;
  margin: 0;
}

.left {

}
i{
  margin-right: 10px;
}
.el-menu-vertical-demo {
  /*flex: 1;*/
  /*width: 150px;*/
  text-align: left;
  min-height: calc(100vh - 60px);
  margin-right: 0!important;
}
</style>